import { useState, useEffect } from "react";
import Avatar from "../Avatar";
import dayjs from "dayjs";

const ChannelList = ({
    activeChannel,
    setActiveChannel,
    fetchList,
    setFetchList,
    messageCount,
    setMessageCount,
}) => {
    const [channels, setChannels] = useState([]);

    const fetchChannels = () => {
        setChannels([]);
        fetch(`/channels`)
            .then((response) => response.json())
            .then((data) => {
                setChannels(data.channels);
                if (data.channels.length > 0) {
                    const firstChannel = data.channels[0];

                    setActiveChannel(firstChannel);
                    setMessageCount((prev) =>
                        [...prev].filter((x) => x !== firstChannel.id),
                    );
                }
            })
            .then(() => {
                if (fetchList) {
                    setFetchList(false);
                    setActiveChannel(activeChannel);
                }
            });
    };

    useEffect(() => {
        fetchChannels();
    }, []);

    useEffect(() => {
        if (fetchList) {
            fetchChannels();
        }
    }, [fetchList]);

    return (
        <div className="overflow-y-scroll overscroll-contain pr-2 pb-2 w-full">
            {channels.length > 0 &&
                channels.map((channel, index) => (
                    <li key={index} className="">
                        <button
                            onClick={() => {
                                setActiveChannel(channel);

                                setMessageCount((prev) =>
                                    [...prev].filter((x) => x !== channel.id),
                                );
                            }}
                            className={`w-full${
                                activeChannel && activeChannel.id === channel.id
                                    ? " active"
                                    : ""
                            }`}
                        >
                            <div className="flex gap-4 items-center overflow-hidden">
                                <div>
                                    <Avatar
                                        url="http://127.0.0.1:8000/images/default/profileAvatar.png"
                                        width="w-8"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <span className="">{channel.name}</span>
                                    <small className="uppercase">
                                        {channel.type}
                                    </small>
                                    <small>
                                        {channel.last_message && (
                                            <span>
                                                {dayjs(
                                                    channel.last_message
                                                ).fromNow()}
                                            </span>
                                        )}
                                    </small>
                                </div>
                            </div>
                            {messageCount.includes(channel.id) && (
                                <div className="badge badge-secondary animate-pulse"></div>
                            )}
                        </button>
                    </li>
                ))}
        </div>
    );
};

export default ChannelList;
