import { usePage } from '@inertiajs/react';
import { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import dayjs from 'dayjs';
import SendMessage from './SendMessage';
import Echo from 'laravel-echo';

const MessageList = ({ channel }) => {
	const { auth } = usePage().props;

	const messagesEndRef = useRef(0);
	const [isBottom, setIsBottom] = useState(true);
	const [allMessages, setAllMessages] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const scrollToBottom = (behaviour) => {
		messagesEndRef.current.scrollIntoView({
			block: 'nearest',
			inline: 'start'
		});
	};

	const handleScroll = (e) => {
		const bottom =
			e.target.scrollHeight - e.target.scrollTop ===
			e.target.clientHeight;
		setIsBottom(bottom);
	};

	useEffect(() => {
		fetchData(1);

		return () => {
			setPage(1);
			setTotalPages(1);
		};
	}, []);

	const fetchData = (pageOverride) => {
		const _page = pageOverride ? pageOverride : page;

		fetch(
			`/channels/${channel.id}/messages?page=${_page}`
		)
			.then((response) => response.json())
			.then((data) => {
				setAllMessages((prev) =>
					[...prev, ...data.messages.data]
						.filter(
							(v, i, a) =>
								a.findIndex(
									(v2) => v2.id === v.id
								) === i
						)
						.sort(
							(a, b) =>
								new Date(a.created_at) -
								new Date(b.created_at)
						)
				);

				setPage(_page + 1);
				setTotalPages(data.messages.meta.last_page);
			});
	};

	useEffect(() => {
		if (channel) {
			setAllMessages([]);

			fetchData(1);

			window.Echo.private(
				`App.Models.ChatChannel.${channel.id}`
			).listen('ChatMessageSent', (e) => {
				addMessage(e.chatMessage);
			});

			window.Echo.private(
				`App.Models.ChatChannel.${channel.id}`
			).listenForWhisper('ChatMessageSent', (e) => {
				// console.log(e.name);
			});

			return () => {
				window.Echo.leave(
					`App.Models.ChatChannel.${channel.id}`
				);
			};
		}
	}, [channel]);

	useEffect(() => {
		if (isBottom) {
			scrollToBottom();
		}
	}, [allMessages]);

	const addMessage = (message) => {
		setAllMessages((prevState) => {
			const x = [...prevState, message].sort(
				(a, b) =>
					new Date(a.created_at) -
					new Date(b.created_at)
			);

			return x;
		});

		scrollToBottom();
	};

	return (
		<>
			<h2 className="pl-4 pb-4">{channel.name}</h2>
			<div
				className="overflow-y-scroll px-4 overscroll-contain grow"
				onScroll={handleScroll}
			>
				<InfiniteScroll
					pageStart={1}
					loadMore={fetchData}
					hasMore={page <= totalPages}
					isReverse={true}
					useWindow={false}
					initialLoad={false}
					loader={
						<div
							key={0}
							className="flex flex-col items-center content-center w-full"
						>
							<span className="loading loading-spinner"></span>
						</div>
					}
				>
					{allMessages.length > 0 ? (
						allMessages.map(
							(message, index) => (
								<div
									key={`message-id-${index}`}
									className={`chat mb-3 ${
										message.user.id ===
										auth.user.id
											? 'chat-end'
											: 'chat-start'
									}`}
								>
									<div className="chat-image avatar">
										<div className="w-10 rounded-full">
											<img
												src={
													message
														.user
														.avatar
												}
											/>
										</div>
									</div>
									<div className="chat-header mb-2">
										{
											message.user
												.username
										}
										<time className="text-xs opacity-50 ml-2">
											{dayjs(
												message.created_at
											).format(
												'D MMM YYYY HH:mm'
											)}
										</time>
									</div>
									<div
										className={`chat-bubble ${
											message.user
												.id ===
											auth.user.id
												? 'chat-bubble-info'
												: ''
										}`}
									>
										{message.message}
									</div>
								</div>
							)
						)
					) : (
						<span>No Messages</span>
					)}
				</InfiniteScroll>
				<div ref={messagesEndRef}></div>
			</div>
			<div className="px-4 pt-4">
				<SendMessage
					channel={channel}
					addMessage={addMessage}
				/>
			</div>
		</>
	);
};

export default MessageList;
