import { useState } from 'react';
import { Link, usePage } from '@inertiajs/react';
import Dropdown from '@/Components/Dropdown';
import Indicator from './Indicator';
import ChannelList from './Chat/ChannelList';
import MessageList from './Chat/MessageList';
import { useEffect } from 'react';
import TextInput from './Form/TextInput';

export default function Messages({ messageUser, profile }) {
	const { auth } = usePage().props;

	const unreadChannels = auth.user?.unread_channels ?? [];

	const [activeChannel, setActiveChannel] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [users, setUsers] = useState(null);
	const [searchInput, setSearchInput] = useState(null);
	const [fetchList, setFetchList] = useState(false);
	const [messageCount, setMessageCount] = useState([...unreadChannels]);

	const searchUsers = async (e) => {
		if (!searchInput || searchInput?.length === 0) return;

		const response = await axios.get(route('users.index') + '?search=' + searchInput);

		const { users } = await response.data;

		setUsers(users);
	};

	const createChannel = async (id) => {
		const response = await axios.post(route('channels.store'), {
			end_client: id,
			type: 'user'
		});

		const { channel } = await response.data;

		setFetchList(true);

		setActiveChannel(channel);

		setSearchInput(null);
		setUsers(null);
	};

	const showActiveChannel = async (id) => {
		const savedChannel = await messageUser(id);

		setActiveChannel(savedChannel);
		setIsOpen(true);
	};

	useEffect(() => {
		if (searchInput !== '' || searchInput !== null) {
			searchUsers();
		}
	}, [searchInput]);

	useEffect(() => {
		setActiveChannel(null);

		window.Echo.private(`messages.summary.user.${auth.user.id}`).listen(
			'ChatChannelCountUpdate',
			(e) => {
				if (e.newMessage) {
					setMessageCount((prev) => [...new Set([...prev, e.newMessage])]);
				}
			}
		);

		return () => {
			window.Echo.leave(`messages.summary.user.${auth.user.id}`);
		};
	}, []);

	return (
		<Indicator value={0}>
			<Link href={route('notifications')} className="btn btn-circle md:hidden">
				<div className="indicator">
					<span className="material-icons-outlined">chat</span>
				</div>
			</Link>
			<div className="hidden md:block">
				<Dropdown
					toggleAction={() => {
						if (profile) {
							showActiveChannel(profile);
						} else {
							setIsOpen(!isOpen);
						}
					}}
					setActiveChannel={setActiveChannel}
					messageCount={messageCount}
					setMessageCount={setMessageCount}
					messages={true}
				>
					<Dropdown.Trigger classes="btn btn-circle align-top">
						<div className="indicator">
							{messageCount.length > 0 && !profile && (
								<span className="indicator-item badge badge-secondary">
									{messageCount.length}
								</span>
							)}
							<span className="material-icons-outlined">chat</span>
						</div>
					</Dropdown.Trigger>
					<Dropdown.Content
						width="w-[75vw]"
						extraClasses="bg-base-100 p-0 overflow-hidden"
					>
						<div className="flex h-[80vh] min-h-[50px] overflow-hidden">
							{!profile && (
								<div className="flex flex-col overflow-hidden basis-80 min-w-[20rem] pl-2">
									<div className="flex justify-between items-center px-4 py-4">
										<h2 className="font-bold text-lg">Messages</h2>
									</div>
									<div className="mb-2 relative">
										<div className="pr-2">
											<TextInput
												onChange={(e) => setSearchInput(e.target.value)}
												classes={'w-full'}
											/>
										</div>

										{searchInput !== '' && (
											<div className="max-h-[200px] overflow-y-auto absolute bg-base-200 rounded-lg mt-1 z-50 w-full">
												{users?.map((user) => {
													return (
														<div className="p-4 flex !flex-row justify-between w-full">
															<span>{user.username}</span>
															<span
																onClick={() =>
																	createChannel(user.id)
																}
																className="material-icons-outlined cursor-pointer"
															>
																chat
															</span>
														</div>
													);
												})}
											</div>
										)}
									</div>
									<ChannelList
										activeChannel={activeChannel}
										setActiveChannel={setActiveChannel}
										fetchList={fetchList}
										setFetchList={setFetchList}
										messageCount={messageCount}
										setMessageCount={setMessageCount}
									/>
								</div>
							)}
							<div className="flex flex-col grow overflow-hidden bg-base-300 py-4">
								{activeChannel !== null && (
									<MessageList channel={activeChannel} profile={profile} />
								)}
							</div>
						</div>
					</Dropdown.Content>
				</Dropdown>
			</div>
		</Indicator>
	);
}
