import TextInput from "@/Components/Form/TextInput";
import PrimaryButton from "@/Components/PrimaryButton";
import { useState } from "react";

const SendMessage = ({ channel, addMessage }) => {
    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState(false);

    const sendMessage = (e) => {
        e.preventDefault();

        if (message.length === 0) {
            return;
        }

        setProcessing(true);

        const config = {
            headers: {
                "X-Socket-ID": window.Echo.socketId(),
            },
        };

        window.axios
            .post(
                route("messages.store", channel.id),
                {
                    message: message,
                },
                config,
            )
            .then((response) => {
                addMessage(response.data.message);
                setMessage("");
                setProcessing(false);
            });
    };

    return (
        <form onSubmit={sendMessage} className="flex gap-4">
            <TextInput
                classes="w-full"
                name="message"
                value={message}
                onChange={(e) => {
                    window.Echo.private(
                        `App.Models.ChatChannel.${channel.id}`,
                    ).whisper("ChatMessageSent", {
                        name: "Typer",
                    });

                    setMessage(e.target.value);
                }}
                autoComplete="off"
            />
            <PrimaryButton
                type="submit"
                disabled={message.length === 0}
                processing={processing}
            >
                <span className="material-icons-outlined">send</span>
            </PrimaryButton>
        </form>
    );
};

export default SendMessage;
